import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";

export default createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route
        lazy={async () => {
          return {
            Component: (await import("@app/guard/StartupGuard")).default,
          };
        }}
      >
        <Route
          path="/"
          lazy={async () => {
            return {
              Component: (await import("@app/pages/index/Index")).default,
            };
          }}
        />
        <Route
          path="redirect/:id"
          lazy={async () => {
            return {
              Component: (await import("@app/pages/Redirect")).default,
            };
          }}
        />
        <Route
          path="login"
          lazy={async () => {
            return {
              Component: (await import("@app/pages/Login")).default,
            };
          }}
        />
        <Route
          path=":username"
          lazy={async () => {
            return {
              Component: (await import("@app/guard/AuthGuard")).default,
            };
          }}
        >
          <Route
            index
            path="*"
            lazy={async () => {
              return {
                Component: (await import("@app/roles")).default,
              };
            }}
          ></Route>
        </Route>
      </Route>
    </>
  )
);
