import "./globals.css";
import { RouterProvider } from "react-router-dom";
import routes from "./routes";
import { Suspense } from "react";

function App() {
  return (
    <main className="antialiased grainy">
      <Suspense>
        <RouterProvider router={routes} />
      </Suspense>
    </main>
  );
}

export default App;
