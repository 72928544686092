import ReactDOM from "react-dom/client";
import App from "@app/App";
import reportWebVitals from "@/reportWebVitals";
import "./i18n";
import { Toast } from "@radix-ui/react-toast";
import { Toaster } from "@components/ui/toaster";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <>
    <Toaster />
    <App />
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
