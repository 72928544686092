import dashboard from "./dashboard.en.json";
import moeys from "./moeys.en.json";
import report from "./report.en.json";
import sis from "./sis.en.json"
export const en = {
  ...dashboard,
  ...moeys,
  ...report,
  ...sis
};
